import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"

export default () => (
    <div style={{ color: 'teal'}}>
        <Link to="/">Home</Link>
        <Header headerText="Contact" />
        <p>Send me a message</p>
        <p>mail : <a href="mailto:nyakkiman@gmail.com">nyakkiman@gmail.com</a></p>
    </div>
)